.popup {
    max-width: 500px;
    margin-top: 5px !important;
    margin: auto;

    &-title {
        text-align: center;
    }

    &-actions {
        &__button {
            height: 54px;
            width: 100%;
            display: grid !important;
            align-content: center;
        }
    }
}

.onvif-wrapper {
  display: grid;
  padding-top: 3px;
  height: 51px;

  &onvif-wrapper__button {
    height: 51px;
    width: 100%;
    display: grid !important;
    align-content: center;
  }
}
