@import "variables";

@mixin main-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-container {
        animation: rotator 1.4s linear infinite;

        &__circle {
            stroke: $primary-accent;
            stroke-dasharray: 187;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: dash 1.4s ease-in-out infinite;

            @keyframes dash {
                0% {
                    stroke-dashoffset: 187;
                }

                50% {
                    stroke-dashoffset: 46.75;
                    transform: rotate(135deg);
                }

                100% {
                    stroke-dashoffset: 187;
                    transform: rotate(450deg);
                }
            }
        }
    }
}

@mixin text-description {
    color: rgba(0, 0, 0, 0.54);
}

@mixin text-description--small {
    @include text-description;
    font-size: 75%;
}

@mixin rdea-hint {
    font-size: 12px;
    color: $gray;
    margin: 0;
}

@mixin link {
    cursor: pointer;
    color: $primary-accent;
    font-weight: bold;
}

@function px($pxsize) {
  @return  calc($pxsize / 16) * 1rem;
}
