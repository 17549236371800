.form-block,
.popup-block {
    max-width: 500px;
    padding: 0px 40px;
    margin: auto;

    &__paragraph,
    &__title {
        text-align: center;
    }

    &-actions {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 10px;

        &__submit-button,
        &__report-button {
            width: 100%;
            text-align: center;
            height: 54px;
        }

        &__report-button {
          margin-top: 16px !important;
        }
    }
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1em) scale(0.75) !important;
    margin-top: -0.9em;
}
