.mat-dialog-container {
    
    padding: 0 !important;
    border-radius: 18px !important;
}

.mat-dialog-actions {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
}

.cdk-overlay-container {
    .cdk-overlay-dark-backdrop {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .cdk-overlay-backdrop-player {
        @media screen and (max-width: 800px) {
            background-color: black !important;
        }

        @media screen and (min-width: 801px) {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    .cdk-overlay-pane {
        &.cdk-overlay-pane-fullscreen {
          @media screen and (max-width: 800px) {
            max-width: 100vw !important;
            max-height: 100vh;
            position: absolute !important;
            top: 0;
            height: 100vh;

            .mat-dialog-container {
                border-radius: 0;
            }
          }
        }

        &.cdk-overlay-pane-custom {
            transform: none !important;
            max-width: 1000px !important;
            width: auto !important;
            z-index: 1000;
            padding: 0 10px;
        }

        &.cdk-overlay-pane-player {
            transform: none !important;
            z-index: 1000;

            @media screen and (max-width: 800px) {
                min-width: 100vw !important;
                max-width: 100vw !important;
                padding: 0;
            }

            @media screen and (min-width: 801px) {
                min-width: 600px !important;
                max-width: 80vw !important;
                padding: 0 10px;
            }

            .mat-dialog-container {
                border-radius: 0;
            }
        }
    }
}
