mat-chip {
    &.mat-standard-chip {
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        display: inline-flex;
        padding: 7px 12px;
        border-radius: 16px;
        align-items: center;
        cursor: pointer;
        min-height: 32px;
        height: 1px;
        box-sizing: border-box;

        &:not([selectable]),
        &:disabled {
            cursor: default !important;
        }
    }

    &.mat-primary,
    &[ng-reflect-color="primary"],
    &[color="primary"] {
        background-color: $primary-accent !important;
        -webkit-text-fill-color: white;
        color: white !important;

        &[ng-reflect-disabled="true"],
        &:disabled {
            background-color: $neutral !important;
            -webkit-text-fill-color: $primary-accent !important;
            color: $primary-accent !important;
        }
    }

    &[ng-reflect-color="primary-inverse"],
    &[color="primary-inverse"] {
        background-color: $neutral !important;
        -webkit-text-fill-color: $primary-accent;
        color: $primary-accent !important;

        &:disabled {
            background-color: rgba($neutral, 0.6) !important;
            -webkit-text-fill-color: rgba($primary-accent, 0.6);
            color: rgba($primary-accent, 0.6) !important;
        }
    }

    &.mat-success,
    &[ng-reflect-color="success"],
    &[color="success"] {
      background-color: $success !important;
      -webkit-text-fill-color: white !important;
      color: white;
    }

    &.mat-warn,
    &[ng-reflect-color="warn"],
    &[color="warn"] {
        background-color: $warn-strong !important;
        -webkit-text-fill-color: white !important;
        color: white;
    }

    &.mat-error,
    &[ng-reflect-color="error"],
    &[color="error"] {
      background-color: $error-strong !important;
      -webkit-text-fill-color: white !important;
      color: white;
    }

    &[ng-reflect-color="neutral"],
    &[color="neutral"] {
        background-color: $gray-light !important;
        -webkit-text-fill-color: white !important;
        color: white;
    }

    &.mat-standard-chip {
        &.mat-chip-disabled {
            opacity: 1 !important;
        }
    }
}

.mat-chip-list {
    .mat-chip-list-wrapper {
        &:focus {
            outline: none !important;
        }
    }

    &--place-content {
        .mat-chip-list-wrapper {
            place-content: flex-end;
        }
    }

    &:focus {
        outline: none !important;
    }
}
