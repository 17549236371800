@import "variables";

.link {
    cursor: pointer;

    &[ng-reflect-color="primary"],
    &[color="primary"] {
        color: $primary-accent;

        &:hover {
            color: $primary-hover;
        }
    }
}