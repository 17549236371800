@import "variables";

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: initial !important;
    border-radius: 4px;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: rgba(124, 179, 66, 0.2) !important;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: rgba(124, 179, 66, 0.2) !important;
}

.mat-checkbox.mat-checkbox-disabled { 
    .mat-checkbox-frame {
        border-color: $disabled-color;
    }

    .mat-checkbox-checkmark-path {
        stroke: $disabled-color !important;
    }
}

.mat-checkbox {
    .mat-checkbox-layout {
        white-space: pre-line !important;
        &--black {
            color: black;
        }
    }

    .mat-checkbox-inner-container {
        width: 20px !important;
        height: 20px !important;
    }

    .mat-checkbox-frame {
        border-radius: 4px;
        border: 1px solid $primary-accent;
    }

    .mat-checkbox-ripple {
        left: calc(50% - 15px) !important;
        top: calc(50% - 15px) !important;
        height: 30px;
        width: 30px;
    }

    .mat-checkbox-persistent-ripple {
        width: 30px;
        height: 30px;
    }

    .mat-checkbox-checkmark-path {
        stroke: $primary-accent !important;
    }
}

.rd-checkbox.mat-checkbox {
    min-width: 360px;
    width: 100%;
    @media screen and (max-width: 800px) {
        min-width: 325px;
        
    }    
    font-weight: 700;
    padding: 16px 0 3px;

    .mat-checkbox-mixedmark {
        background-color: $primary-accent;
      }
      
    .mat-checkbox-layout {
        display: flex;
        white-space: pre-line !important;
    }

    .mat-checkbox-inner-container {
        order: 2;
       
    }
    .mat-checkbox-label{
        order: 1;
    }
}