@media screen and (max-width: 800px) {
  .yandex-map-block {
    height: 130px;
  }
}

@media screen and (min-width: 801px) {
  .yandex-map-block {
    height: 125px;
  }
}

.yandex-map-block {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  padding-bottom: 1.34375em;
}
