.table-page-container {
  &__loaders {
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &__main {
      &__wrapper {
        flex: 1;
      }
    }
    &--devider {
      margin: 0;
    }
  }
    mat-card {
        height: 100%;
        height: -moz-avaliable;
        height: -webkit-fit-content;
        margin: 10px;
        border-radius: 8px;
        background: white !important;

        mat-card-content {
            .input-field {
                display: grid;
                grid-template-columns: auto fit-content(100%);
                grid-gap: 10px;
            }
        }

        mat-card-title-group {
            max-width: calc(100% - 100px) !important;
        }
    }
}
