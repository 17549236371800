@use '@angular/material' as mat;
@import "mixins";
@import "variables";
@import "modules/index";
@import "material/index";
@import "../assets/theme.css";
@import "primeicons/primeicons.css";

@include mat.core();
@include mat.all-component-themes($rdea-theme);

// ***
// *** DEFINE GLOBAL STYLE
// ***
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  background: $background-light;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $scrollbar-color;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

* {
  font-family: "arial";

  @font-face {
    font-family: "Material Icons" !important;
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
    font-display: swap;
  }

  .material-icons {
    font-family: "Material Icons" !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

html,
body {
  -webkit-text-size-adjust: none;
  height: 100%;
}

a,
p,
h1,
h2,
h3,
h4,
span,
mat-card {
  -moz-user-select: text !important;
  -khtml-user-select: text !important;
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

a,
label {
  color: #3c4761;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

// ***
// DADATA SUGGESTIONS
// ***

.suggestions-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  vertical-align: top;
  min-height: 1px;
  -webkit-text-size-adjust: 100%;
}

.suggestions-addon {
  background: rgba(255, 255, 255, 0.75) 50% 50% no-repeat;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.suggestions-constraints {
  list-style: none !important;
  position: absolute !important;
  white-space: nowrap !important;
}

.suggestions-suggestions {
  position: absolute !important;
  left: -10px !important;
  top: 15px !important;
  width: calc(100% + 20px) !important;
  background: #fff;
  border: 1px solid #999;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: default;
  z-index: 500;
  -webkit-text-size-adjust: 100%;
}

.suggestions-suggestion {
  padding: 5px !important;
}

// ***
// PLYR PLAYER
// ***

.action-block {
  text-align: center !important;
}

.mat-sidenav-container {
  background: $neutral-hover;


  &:not(.mobile) {
    margin-top: 0px;
    height: calc(var(--vh, 1vh) * 100);
  }

  &.mobile {
    height: 100%;
    padding-top: 64px;
  }
}

app-abonent-page {
  .mat-form-field-appearance-legacy {
    .mat-form-field-underline {
      height: 0px !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }

    .mat-form-field-ripple {
      display: none !important;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $primary-accent !important;
  }

  textarea {
    color: black !important;
  }
}

app-chats-page {
  .mat-form-field-flex {
    padding: 0px 10px 0px 10px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline-end {
      border-left: 0px;
    }
  }
}

.mat-tab-group {
  margin-top: 0px;
}

.mat-tab-label,
.mat-tab-link {
  color: white !important;
}

.filter-area {
  margin: 0px 0px 5px 0px;

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  mat-form-field {
    width: 100%;
  }
}

.rdea-table-hover-row-active {
  background: $gray;
}

mat-label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mat-input-element {
  caret-color: black !important;
}

app-abonent-accounts {
  .mat-expansion-panel {
    margin: 0 !important;
    box-shadow: none !important;
    border: 0 solid $border-color;
    border-radius: 8px;
  }

  .mat-expansion-panel-header[aria-disabled="true"] {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

app-cameras-warnings {
  .mat-expansion-indicator::after {
    color: $warn-strong !important;
  }
}

mat-panel-title {
  width: -webkit-fill-available;
  width: -moz-available;
  width: 100%;
}

.suggestions-promo {
  display: none !important;
}

.mat-menu-panel {
  min-height: auto !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-5 {
  padding-right: 5px;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.bold-text {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

.date-field-small,
.date-field-large {
  margin: 0;
  color: rgba(0, 0, 0, 0.54);
}

.date-field-small {
  font-size: 8pt;
}

hr {
  border-top: 1px solid rgba(189, 189, 189, 0.45);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;

  @media screen and (max-width: 800px) {
    margin: 10px 0;
  }

  @media screen and (min-width: 801px) {
    margin: 16px 0;
  }
}

.form-container {
  max-width: 500px;
  margin: auto;

  input:focus {
    outline: none;
  }

  h3,
  p {
    text-align: center;
  }

  .action-block {

    a,
    button {
      width: 100%;
    }
  }
}

app-entrances-page,
rdea-adapters-page,
rdea-abonents-page,
app-new-adapters-page,
app-batches-page,
app-shields-page,
app-shield-tests,
app-orders-page,
app-sign-ups-table,
app-video-surveillance-list-page,
app-gates-page,
app-entrance-page,
app-dashboard-rdos-images {
  app-empty-data {
    div {
      margin: 10px;
      border-radius: 8px;
    }
  }
}

app-camera-table {
  app-camera-thumbnail {
    img {
      max-width: 200px;
    }
  }
}

.mat-button-wrapper {
  font-weight: bold;
}

.index {
  width: 100%;
  min-height: 100%;
  background: $background;
  position: absolute;

  &-loader {
    @include main-loader;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke: $primary-accent;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

app-create-software-intercom-form,
app-create-entrance-form,
app-edit-rda-form,
app-company-sign-up-request-page,
app-company-register-page,
app-rda-translation-form,
app-change-password-form {
  .dimmer {
    position: absolute !important;
    left: 0px !important;
  }
}

.text-align-center {
  text-align: center;
}

app-login-form,
app-company-register-form,
app-company-sign-up-request-form {
  .component-loader-container {
    margin: auto;

    .component-loader .path {
      stroke: white;
    }
  }
}

.error-ic {
  color: $error-strong;
}

.warn-ic {
  color: $warn-strong;
}

app-navbar {
  display: block;
}

app-gate-page {
  .empty-data-block {
    margin: 10px;
    border-radius: 8px;
  }
}

app-services-page {
  mat-cell[data-label="statuses"] {
    mat-icon:not(.warn) {
      &.active path {
        fill: $success !important;
      }

      &.inactive path {
        fill: $error-strong !important;
      }

      &.warn path {
        fill: $warn-strong !important;
      }

      &.neutral path {
        fill: $gray-light !important;
      }

      &.blocked path {
        fill: $gray-light !important;
      }
    }

    mat-icon.warn {
      &.active {

        path,
        line {
          stroke: $success !important;
        }
      }

      &.inactive {

        path,
        line {
          stroke: $error-strong !important;
        }
      }

      &.warn {

        path,
        line {
          stroke: $warn-strong !important;
        }
      }

      &.neutral {

        path,
        line {
          stroke: $gray-light !important;
        }
      }
    }
  }
}

.tooltip {
  font-size: 14px;
}
