@charset "UTF-8";
@use '@angular/material' as mat;


/*
    GENERAL STYLES
*/
$primary-accent: #0da7e2;
$primary-hover: #0fb7fa;
$primary-gradient: linear-gradient(197.79deg, #39c2fe 26%, #7b33f5 259%);
$secondary-gradient: linear-gradient(187.79deg, #39c2fe 45%, #39c2fe 54%, #7b33f5 140%);
$new-main-gradient: linear-gradient(235.84deg, #39ABFE 0%, #7B33F5 100%);
$dark-gray: #6C757D;


$success: #33c51b;
$success-hover: #30bd1a;

$warn: #ffeded;
$warn-strong: #ff9e0d;
$warn-hover: #ffa80d;
$warn-bg: rgba(245, 202, 89, 0.7);
$warn-orange: #E88F4F;

$warn-link:#EF5E67;
$warn-link-bg:rgba(239, 94, 103, 0.2);

$error: #ffe3e3;
$error-strong: #ed1010;
$error-hover: #ff1212;
$error-cron: rgba(239, 94, 103, 0.5);

$info: rgba(13, 167, 226, 0.2);

$black:#000000;
$gray: #7d7d7d;
$text-gray:#757685;
$gray-hover: #707070;
$gray-light: #bdbdbd;
$gray-light-hover: #ababab;
$neutral: #edf2f5;
$neutral-hover: #dfe3e6;
$border-color: rgba($gray-light, 0.45);
$table-border-color: rgba($gray-light, 0.2);
$form-border-color:#e6e6e9;
$background: #e6ecf0;
$background-white: #ffffff;
$disabled-color: #9e9e9e;

$green: #5fcf8c;
$red: #ef5e67;

/*
    Upload area
*/
$background-light: #EFF2F5;
$dark-blue: #386296;

/*
    ANGULAR MATERIAL THEME
*/
$rdea-primary: mat.define-palette(mat.$indigo-palette);
$rdea-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$rdea-warn: mat.define-palette(mat.$red-palette);
$rdea-theme: mat.define-light-theme($rdea-primary, $rdea-accent, $rdea-warn);

/*
    VIDEO PLAYER
*/
$video-player-picker: red;
$video-player-picker-radius: 50%;
$video-player-event: #F2CB00;
$video-player-gap: #757575;


/*
    SCROLLBAR
*/

$scrollbar-color:#D7DBE0;
