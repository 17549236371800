mat-form-field {
  width: -webkit-fill-available;
  width: -moz-available;
  width: 100%;
  min-width: 60px;

  &.relative-size {
    .mat-form-field-subscript-wrapper {
      position: relative !important;
    }
  }

  &.none-padding {
    .mat-form-field-wrapper {
      padding-bottom: 0px;
    }
  }

  &.none-margin {
    .mat-form-field-wrapper {
      margin: 0;
    }
  }

  &.mat-form-field-small {
    .mat-form-field-label-wrapper {
      margin-top: -5px;
    }

    .mat-form-field-infix {
      border-top-width: 7px;
      padding: 7px 0px;
    }

    .mat-select-arrow-wrapper {
      transform: none !important;
    }

    .mat-form-field-flex {
      height: 36px;
    }
  }

  .mat-form-field-outline-end {
    border-radius: 0 8px 8px 0 !important;
  }

  .mat-form-field-outline-start {
    border-radius: 8px 0 0 8px !important;
  }

  &.mat-focused {
    &.mat-primary {
      .mat-select-arrow {
        color: black !important;
      }
    }

    .mat-form-field-ripple {
      background-color: black;
    }

    .mat-form-field-label {
      color: black !important;
    }
  }
}

.message-area {
  .mat-form-field-wrapper {
    padding-bottom: 1.34375em !important;
  }
}

.message-area.remove-bottom-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.disable-color.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.disable-color.mat-form-field-appearance-outline .mat-form-field-outline-start, 
.disable-color.mat-form-field-appearance-outline .mat-form-field-outline-end, 
.disable-color.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap  {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.mat-form-field-appearance-outline.disable-color .mat-form-field-infix {
  textarea {
    color: #000 !important;
    overflow: hidden;
  }
}

.disable-color.mat-form-field-appearance-outline .mat-form-field-label-wrapper {
  display: none;
}

.mat-form-field-prefix {
  top: 0px !important;
}

.mat-form-field-ripple {
  background-color: #607d8b !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: black !important;
}

.mat-form-field-infix {
  ymaps {
    z-index: 40000 !important;
  }
}

.mat-form-field.address-input {
  height: 40px;

  .mat-form-field-wrapper {
    height: 40px;

    .mat-form-field-flex {
      .mat-form-field-outline {
        top: .29em;
      }

      .mat-form-field-infix {
        padding-top: 4.5px;
        padding-bottom: 12px;
      }
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float:not(.mat-form-field-should-float) {
    .mat-form-field-label {
      top: 19.5px;
    }
  }
}