@import "variables";

@media screen and (max-width: 1320px) {
    .mat-table {
        grid-gap: 10px;
        background: none !important;

        .mat-row {
            display: block;
            padding: 5px 5px 5px 0px !important;
            border-radius: 8px;
            background: white;
            border: 1px solid $border-color !important;
            box-shadow: 0px 1px 5px -1px $border-color, 0 0px 0px 0px $border-color, 0 0px 0px 0px $border-color;

            &:after {
                display: block !important;
            }

            &:hover {
                background-color: white !important;
            }
        }

        .mat-cell {
            text-align: right;

            &:not(:last-child) {
                border-bottom: 1px solid $border-color;
            }

            &:last-child {
                border-bottom: 0;
            }

            &[label-name] {
                &:before {
                    content: attr(label-name);
                    margin: auto;
                    margin-left: 0;
                    padding-right: 10px;
                    word-break: keep-all;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.54);
                }
            }

            &[label-name=""] {
                &:before {
                    content: none !important;
                }
            }

            &[data-label="actions"] {
                float: right;
            }

            &[data-label="preview"] {
                app-camera-thumbnail {
                    margin-left: auto;
                }

                img {
                    max-width: 250px !important;
                }
            }
        }
    }

    .mat-card-not-empty-content {
        padding: 0px;
    }

    mat-footer-row,
    mat-row {
        min-height: auto !important;
    }

    .filter-area .mat-form-field {
        width: -webkit-fill-available;
        width: -moz-available;
        width: 100%;
    }
}

@media screen and (min-width: 1201px) {
    .table-card .mat-table .mat-row:not(:last-child) {
        border-bottom-color: $border-color !important;
    }
}

.mat-table {
    display: grid;
    border-radius: 8px;

    .mat-cell,
    .mat-row,
    .mat-header-cell,
    .mat-header-row {
        min-height: auto;
        word-break: break-word;
    }

    .mat-cell,
    .mat-row {
        padding: 10px 5px !important;
    }

    .mat-header-cell,
    .mat-header-row {
        padding: 10px 5px;
    }

    .mat-header-row {
        border-bottom-color: $border-color;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media screen and (max-width: 1320px) {
          display: none !important;
        }
    }

    .mat-row {
        border-bottom-color: $border-color;

        &:hover {
            background-color: $table-border-color;
        }

        .mat-cell {

            &.mat-cell-warn,
            &.mat-cell-error {
                color: white;
                border-radius: 8px;
                margin: 5px 0px;

                &[label-name] {
                    &:before {
                        color: white;
                    }
                }
            }

            &.mat-cell-warn {
                background-color: $warn-strong;

            }

            &.mat-cell-error {
                background-color: $error-strong;
            }
        }
    }
}

app-models-page,
app-services-page {
    .mat-cell {
        text-align: left !important;
    }
}
