@import "variables";

app-dashboard-rdos-images {
  mat-card {
    max-width: 1000px;
  }
}

.card-container {
  margin: 10px;
  border-radius: 8px;
  background: white !important;

  mat-card-title-group {
    max-width: calc(100% - 100px) !important;
  }
}

app-abonent-chat {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

// ***
// HEADERS
// ***
h1 {
  font-size: 24px;
}

h2 {
  margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
mat-card-title,
mat-card-subtitle,
mat-panel-title {
  font-weight: bold !important;
  color: $black !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// ***
// MAT CARD
// ***
app-company-sign-up-request-form,
app-company-register-form {
  .mat-card-header-text {
    margin: auto !important;
    text-align: center !important;
  }
}

@media screen and (min-width: 801px) {
  app-orders-page {
    mat-card-footer {
      position: absolute;
      bottom: 15px;
    }
  }
}


@media screen and (min-width: 801px) {
  mat-card {
    padding: 24px !important;
  }
}

@media screen and (max-width: 800px) {
  mat-card {
    padding: 16px !important;
  }
}

mat-card {
  background: initial !important;
  box-shadow: none !important;
  border-radius: 8px !important;

  mat-card-title {
    font-weight: bold !important;
  }

  .mat-card-header-text {
    margin: 0;
  }
}

mat-card-footer {
  margin: 0px !important;
}

.mat-card-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;

  &.header {
    height: 36px;
    display: grid;
    grid-template-columns: 0px auto 64px;

    .title-block {
      margin: auto 0px;

      mat-card-title {
        margin: auto;
      }
    }

    .edit-btn {
      height: 36px;
      width: 60px;
    }
  }
}

// ***
// BUTTONS
// ***
.mat-button,
.mat-raised-button,
.mat-stroked-button {
  box-shadow: none !important;
}

.mat-raised-button,
.mat-stroked-button {
  border-radius: 8px !important;
}

.mat-button-lg {
  height: 54px;
}

app-abonent-page {
  .mat-mini-fab {
    bottom: 20px !important;
    right: 20px !important;
  }
}

.mat-mini-fab {
  box-shadow: none !important;
  height: 24px !important;
  width: 24px !important;

  .mat-icon {
    height: 18px;
    width: 18px;
    margin-top: -18px;
  }

  &.mat-primary,
  &[ng-reflect-color="primary"],
  &[color="primary"] {
    background-color: $primary-accent !important;
    color: white !important;

    &:disabled {
      background-color: rgba($primary-accent, 0.6) !important;

      &:hover {
        background-color: rgba($primary-accent, 0.6) !important;
      }
    }

    &:hover {
      background-color: $primary-hover !important;
    }
  }

  &.mat-success,
  &[ng-reflect-color="success"],
  &[color="success"] {
    color: white !important;
    background-color: $success !important;

    &:disabled {
      background-color: rgba($success, 0.6) !important;

      &:hover {
        background-color: rgba($success, 0.6) !important;
      }
    }

    &:hover {
      color: white !important;
      background-color: $success-hover !important;
    }
  }

  &.mat-warn,
  &[ng-reflect-color="warn"],
  &[color="warn"] {
    background-color: $warn-strong !important;

    &:disabled {
      background-color: rgba($warn-strong, 0.6) !important;

      &:hover {
        background-color: rgba($warn-strong, 0.6) !important;
      }
    }

    &:hover {
      background-color: $warn-hover !important;
    }
  }

  &.mat-error,
  &[ng-reflect-color="error"],
  &[color="error"] {
    color: white !important;
    background-color: $error-strong !important;

    &:disabled {
      background-color: rgba($error-strong, 0.6) !important;

      &:hover {
        background-color: rgba($error-strong, 0.6) !important;
      }
    }

    &:hover {
      background-color: $error-hover !important;
    }
  }

  &.mat-primary-inverse,
  &[ng-reflect-color="primary-inverse"],
  &[color="primary-inverse"] {
    background-color: $neutral !important;
    color: $primary-accent !important;

    &:disabled {
      background-color: rgba($neutral, 0.6) !important;
      color: rgba($primary-accent, 0.6) !important;

      &:hover {
        background-color: rgba($neutral, 0.6) !important;
        color: rgba($primary-accent, 0.6) !important;
      }
    }

    &:hover {
      background-color: $neutral-hover !important;
    }
  }

  &.mat-warn-inverse,
  &[ng-reflect-color="warn-inverse"],
  &[color="warn-inverse"] {
    background-color: $background-light !important;
    color: $red !important;

    &:disabled {
      background-color: rgba($background-light, 0.6) !important;
      color: rgba($red, 0.6) !important;

      &:hover {
        background-color: rgba($background-light, 0.6) !important;
        color: rgba($red, 0.6) !important;
      }
    }

    &:hover {
      background-color: $background-light !important;
    }

    &:focus {
      background-color: $background-light !important;
    }
  }

  &.mat-primary-white,
  &[ng-reflect-color="primary-white"],
  &[color="primary-white"] {
    background-color: white !important;
    color: $primary-accent !important;

    &:disabled {
      background-color: $neutral !important;
      color: $gray-light !important;

      &:hover {
        background-color: $neutral !important;
      }
    }

    &:hover {
      background-color: $neutral !important;
    }
  }
}

.mat-raised-button {
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;

  &.mat-primary,
  &[ng-reflect-color="primary"],
  &[color="primary"] {
    background-color: $primary-accent !important;
    color: white !important;

    &:disabled {
      background-color: rgba($primary-accent, 0.6) !important;

      &:hover {
        background-color: rgba($primary-accent, 0.6) !important;
      }
    }

    &:hover {
      background-color: $primary-hover !important;
    }
  }

  &.mat-success,
  &[ng-reflect-color="success"],
  &[color="success"] {
    color: white !important;
    background-color: $success !important;

    &:disabled {
      background-color: rgba($success, 0.6) !important;

      &:hover {
        background-color: rgba($success, 0.6) !important;
      }
    }

    &:hover {
      color: white !important;
      background-color: $success-hover !important;
    }
  }

  &.mat-warn,
  &[ng-reflect-color="warn"],
  &[color="warn"] {
    background-color: $warn-strong !important;

    &:disabled {
      background-color: rgba($warn-strong, 0.6) !important;

      &:hover {
        background-color: rgba($warn-strong, 0.6) !important;
      }
    }

    &:hover {
      background-color: $warn-hover !important;
    }
  }

  &.mat-warn-inverse,
  &[ng-reflect-color="warn-inverse"],
  &[color="warn-inverse"] {
    background-color: $background-light !important;
    color: $red !important;

    &:disabled {
      background-color: rgba($background-light, 0.6) !important;
      color: rgba($red, 0.6) !important;

      &:hover {
        background-color: rgba($background-light, 0.6) !important;
        color: rgba($red, 0.6) !important;
      }
    }

    &:hover {
      background-color: $background-light !important;
    }
  }

  &.mat-error,
  &[ng-reflect-color="error"],
  &[color="error"] {
    color: white !important;
    background-color: $error-strong !important;

    &:disabled {
      background-color: rgba($error-strong, 0.6) !important;

      &:hover {
        background-color: rgba($error-strong, 0.6) !important;
      }
    }

    &:hover {
      background-color: $error-hover !important;
    }
  }

  &.mat-primary-inverse,
  &[ng-reflect-color="primary-inverse"],
  &[color="primary-inverse"] {
    background-color: $neutral !important;
    color: $primary-accent !important;

    &:disabled {
      background-color: rgba($neutral, 0.6) !important;
      color: rgba($primary-accent, 0.6) !important;

      &:hover {
        background-color: rgba($neutral, 0.6) !important;
        color: rgba($primary-accent, 0.6) !important;
      }
    }

    &:hover {
      background-color: $neutral-hover !important;
    }
  }

  &.mat-primary-white,
  &[ng-reflect-color="primary-white"],
  &[color="primary-white"] {
    background-color: white !important;
    color: $primary-accent !important;

    &:disabled {
      background-color: $neutral !important;
      color: $gray-light !important;

      &:hover {
        background-color: $neutral !important;
      }
    }

    &:hover {
      background-color: $neutral !important;
    }
  }
}

.mat-button {
  &:disabled {
    opacity: 0.5 !important;
  }

  &.mat-primary {
    background-color: none !important;
    color: $primary-accent !important;
  }

  .mat-primary,
  &[ng-reflect-color="primary"],
  &[color="primary"] {
    background-color: none !important;
    color: $primary-accent !important;
  }

  .mat-warn,
  &[ng-reflect-color="warn"],
  &[color="warn"] {
    background-color: none !important;
    color: $warn-strong !important;
  }
}

.mat-stroked-button {

  .mat-primary,
  &[ng-reflect-color="primary"],
  &[color="primary"] {
    color: $primary-accent !important;
    border: none;

    &:hover {
      background-color: $neutral;
      color: $primary-accent;
    }
  }

  .mat-warn,
  &[ng-reflect-color="warn"],
  &[color="warn"] {
    background-color: $gray;
    border: none;

    &:hover {
      background-color: $gray-hover;
    }
  }
}

.mat-icon-button {
  .mat-icon~svg {
    fill: white !important;
  }

  &:disabled {
    opacity: 0.5 !important;
  }

  &.mat-primary {
    background-color: none !important;
    color: $primary-accent !important;
  }

  &[ng-reflect-color="primary"],
  &[color="primary"] {
    color: $primary-accent !important;
    -webkit-text-fill-color: $primary-accent !important;
  }

  &[ng-reflect-color="primary-inverse"],
  &[color="primary-inverse"] {
    color: $primary-accent !important;
    -webkit-text-fill-color: $primary-accent !important;
  }
}

.mat-fab {
  margin: auto !important;
  height: 40px !important;
  width: 40px !important;
  box-shadow: none !important;

  .mat-button-wrapper {
    padding: 0px !important;
  }

  &[ng-reflect-color="primary"],
  &[color="primary"] {
    background-color: $primary-accent !important;
    color: white !important;
    -webkit-text-fill-color: white !important;

    &:hover {
      background-color: $primary-hover !important;
    }
  }

  &[ng-reflect-color="error"],
  &[color="error"] {
    background-color: $error-strong !important;
    color: white !important;
    -webkit-text-fill-color: white !important;

    &:hover {
      background-color: $error-hover !important;
    }
  }

  &[ng-reflect-color="primary-inverse"],
  &[color="primary-inverse"] {
    background-color: $neutral !important;
    color: $primary-accent !important;
    -webkit-text-fill-color: $primary-accent !important;

    &:hover {
      background-color: $neutral-hover !important;
    }
  }

  &[ng-reflect-color="transparent"],
  &[color="transparent"] {
    background-color: transparent !important;
    color: $neutral !important;
    -webkit-text-fill-color: $neutral !important;

    &:hover {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }
  }
}

// ***
// PAGINATOR
// ***
mat-paginator {
  border-radius: 8px;
  margin: 0px 10px 10px 10px;

  .mat-paginator-outer-container {
    border-radius: 8px;
  }

  .mat-paginator-container {
    justify-content: center;
    padding-top: 5px;
  }

  .mat-paginator-range-actions {
    display: block;
  }

  .mat-paginator-range-label {
    text-align: center;
  }

  button[disabled="false"] {
    color: $primary-accent;
  }
}

// ***
// MAT LIST
// ***

app-dashboard-rdos-images {
  .mat-list-item {
    height: max-content !important;

    .mat-list-item-content {
      padding: 10px !important;

      &:hover {
        border-radius: 8px;
      }
    }
  }

  .mat-list-text {
    text-align: left !important;
  }
}

.mat-list-messages {
  overflow-y: auto;
  scrollbar-width: none;
}

@media screen and (max-width: 800px) {
  .mat-list-messages {
    max-height: calc(var(--vh, 1vh) * 100 - 90px) !important;
  }
}

@media screen and (min-width: 801px) {
  .mat-list-messages {
    max-height: calc(var(--vh, 1vh) * 100 - 95px) !important;
  }
}

mat-list {
  .mat-list-item-date {
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .mat-list-text {
    padding-left: 10px !important;
  }

  .mat-list-item-content {
    padding: 0 10px !important;
  }

  .mat-list-item-content:hover {
    background: $border-color !important;
    cursor: pointer;
  }

  .mat-list-item-text {
    font-size: 12px !important;
  }

  .mat-list-item-selected {
    background: $border-color;
  }
}

// ***
// BACKDROP
// ***
.mat-drawer-backdrop {
  z-index: 11 !important;
}

// ***
// DATEPICKER
// ***
.mat-datepicker-content-touch {
  margin: auto !important;
}

.mat-datepicker-toggle-active {
  color: $primary-accent !important;
}

.mat-calendar-body-selected {
  background-color: $primary-accent !important;
}

// ***
// CHECKBOXES
// ***
.mat-pseudo-checkbox-checked {
  background: $success !important;
}

// ***
// MAT TOOLBAR
// ***
@media screen and (max-width: 800px) {
  mat-toolbar {
    width: -webkit-fill-available;
    width: -moz-available;
    width: 100%;

    h1 {
      color: white !important;
      margin: auto !important;
      text-align: center !important;
    }
  }
}

@media screen and (min-width: 801px) {
  mat-toolbar {
    width: calc(100% - 240px);
    display: none !important;
  }
}

mat-toolbar {
  background: $primary-gradient !important;
  height: 64px !important;
  padding-left: 10px !important;

  button {
    color: white !important;
  }
}

app-top-bar {
  position: fixed !important;
  width: -webkit-fill-available;
  width: -moz-available;
  width: 100%;
  z-index: 101;
  -webkit-overflow-scrolling: touch;
}

// ***
// MAT SIDENAV
// ***
mat-sidenav {
  z-index: 11 !important;
  border: none !important;
  width: 240px;

  .mat-drawer-inner-container {
    height: 100%;
    z-index: 100;

    a {
      display: flex;

      img {
        width: calc(100% - 48px);
        height: 100%;
        padding: 24px 24px 0px 24px;
      }
    }

    mat-nav-list {
      padding-top: 0px;
    }
  }
}

mat-sidenav-content {
  &.mat-drawer-content {
    scrollbar-width: none;
  }
}

// ***
// MAT SNACK BAR
// ***
.mat-snack-bar-container {
  border-radius: 8px !important;
}

@media screen and (min-width: 801px) {
  .mat-snack-bar-container {
    max-width: 50vw !important;
  }
}

.mat-snack-bar-handset {
  width: auto !important;
}

// ***
// MAT STEPPER
// ***

mat-horizontal-stepper {
  .mat-horizontal-stepper-header-container {
    margin-bottom: 10px;

    .mat-step-icon-selected,
    .mat-step-icon-state-edit {
      background-color: $primary-accent !important;
    }

    .mat-step-label {
      padding: 0 !important;
    }
  }

  .mat-horizontal-content-container {
    padding: 0px !important;
  }
}

// ***
// MAT SLIDER
// ***

.mat-slider {
  &.mat-slider-disabled {
    .mat-slider-thumb {
      background-color: rgba(0, 0, 0, 0.26) !important;
    }

    .mat-slider-track-fill {
      background-color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  &:not(.mat-slider-disabled) .mat-slider-thumb {
    background-color: $primary-accent !important;
    transform: none !important;
  }

  &:not(.mat-slider-disabled) .mat-slider-track-fill {
    background-color: $primary-accent !important;
  }

  .mat-slider-thumb-label {
    background-color: $success !important;
  }
}

// ***
// MAT SLIDE TOOGLE
// ***

.mat-slide-toggle {
  margin: auto 0px;

  .mat-slide-toggle-ripple {
    top: calc(50% - 15px) !important;
    left: calc(50% - 15px) !important;
    height: 30px !important;
    width: 30px !important;

    .mat-ripple-element {
      top: 0px !important;
      left: 0px !important;
      width: 30px !important;
      height: 30px !important;
      border-radius: 50% !important;
    }
  }

  .mat-slide-toggle-bar {
    height: 22px !important;
    border-radius: 15px !important;
    margin-left: 0 !important;

    .mat-slide-toggle-thumb-container {
      top: 1px !important;
    }
  }

  &:not(.mat-checked) {
    .mat-slide-toggle-bar {
      height: 24px !important;
      background-color: $neutral !important;
      border: 1px solid $neutral-hover;
    }

    .mat-slide-toggle-thumb-container {
      left: 1px !important;
      top: 2px !important;
    }
  }

  &:not(.mat-checked) {

    &.mat-error,
    &[ng-reflect-color="error"],
    &[color="error"] {
      .mat-slide-toggle-thumb {
        background-color: white !important;
      }

      .mat-slide-toggle-bar {
        background-color: $error-strong !important;
      }

      .mat-ripple-element {
        background-color: rgba($error-strong, 0.5) !important;
      }
    }
  }

  &.mat-checked {

    &,
    &.mat-primary,
    &[ng-reflect-color="primary"],
    &[color="primary"] {
      .mat-slide-toggle-thumb {
        background-color: white !important;
      }

      .mat-slide-toggle-bar {
        background-color: $primary-accent !important;
      }

      .mat-ripple-element {
        background-color: rgba($primary-accent, 0.5) !important;
      }
    }

    &.mat-success,
    &[ng-reflect-color="success"],
    &[color="success"] {
      .mat-slide-toggle-thumb {
        background-color: white !important;
      }

      .mat-slide-toggle-bar {
        background-color: $success !important;
      }

      .mat-ripple-element {
        background-color: rgba($success, 0.5) !important;
      }
    }

    .mat-slide-toggle-thumb-container {
      left: -1px !important;
    }
  }
}

// ***
// MAT OPTION
// ***
.mat-option {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  width: 100% !important;
  height: unset !important;

  span {
    white-space: normal !important;
  }
}

.mat-option-warning {
  background-color: $warn !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black !important;
}

// ***
// MAT BOTTOM SHEET
// ***
mat-bottom-sheet-container {
  max-width: 550px !important;
}

// ***
// MAT SELECT
// ***


.mat-select-custom {
  margin-top: 30px !important;
}

.mat-select-content,
.mat-select-panel-done-animating {
  max-height: -webkit-fill-available;
  min-height: 46px;
  display: grid !important;
  border: solid 1px #fefefe;
  background: #fafafa !important;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

// ***
// MAT ACCORDION
// ***
.mat-accordion .mat-expansion-panel {
  &:last-of-type {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  &:first-of-type {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }
}

// ***
// MAT EXPANSION PANEL
// ***

app-service-rda-logs {
  .mat-expansion-panel {
    border: 1px solid $border-color;
    box-shadow: 0px 1px 5px -1px $border-color, 0 0px 0px 0px $border-color, 0 0px 0px 0px $border-color !important;

    .mat-expansion-panel-header {
      font-size: 20px;
    }
  }
}

.mat-expansion-panel {
  border-radius: 8px !important;

  .mat-expansion-panel-body {
    padding: 0 10px 10px !important;
    position: relative;
  }

  .mat-expansion-panel-header {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-left: -10px;

    .mat-expansion-panel-header-title {
      //margin: auto !important;
      margin-left: 4px;
    }
  }
}

// ***
// MAT PROGRESS BAR
// ***

.mat-progress-bar {
  border-radius: 8px !important;

  &-fill::after {
    background-color: $primary-accent !important;
  }

  &-buffer {
    background-color: rgba($primary-accent, 20%) !important;
  }

  &-background {
    fill: rgba($primary-accent, 20%) !important;

    rect {
      fill: rgba($primary-accent, 20%) !important;
    }
  }
}
