@import "variables";

.plyr {
    right: 0px !important;

    @media screen and (display-mode: browser) {
        height: 95vh;
        width: 110vh;

        @media (max-width: 800px) {
            &__video-wrapper {
                position: absolute !important;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
            }

            &__controls {
                opacity: 1 !important;
                transform: none !important;
            }
        }
    }

    &__captions {
        display: contents !important;
    }

    &__custom-progress-bar {
        height: 15px;
        width: 100%;
        position: relative;
        left: 0px;
        background-color: white;
        cursor: pointer;
        overflow: scroll;

        &-wrapper {
            position: relative;
            width: 100%;
            height: 15px;
        }
    }

    &__custom-picker {
        display: none;
        position: absolute;
        top: -2px;
        height: 19px;
        width: 19px;
        background: $video-player-picker;
        border-radius: $video-player-picker-radius;
    }

    &__gaps {
        position: absolute;
        height: 15px;
        width: 100%;
        top: 0px;
        left: 0px;

        &-gap {
            height: 15px;
            position: absolute;
            background: #bdbdbd;
        }
    }

    &__menu__container {
        &:after {
            content: none !important;
        }

        &-wrapper {
            overflow-y: scroll;

            &-content {
                max-height: 400px;
            }
        }

        .plyr__control {
            &--forward {
                padding-right: inherit !important;
            }

            &:after {
                content: none !important;
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        pointer-events: all !important;

        @media screen and (max-width: 800px) {
            padding: 10px 20px;
        }

        .video-player {

            &-timeline, 
            &-buttons {
                display: flex;
                gap: 5px;
            }

            &-buttons {
                margin-right: 50px;
            }

            &-timeline {
                width: 100%;
                align-items: center;
            }
        }

        &__progress-time {
            width: 75px !important;
        }

        &--archive {
            flex-direction: column;

            .video-player {
                &-buttons {
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }

        &__item {
            @media screen and (max-width: 800px) {
                &[data-plyr="fullscreen"] {
                    display: none !important;
                }
            }

            &__events {
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                gap: 3px;
            }
        }

        &-events-button,
        &-seeking-button {
            cursor: pointer;
            position: absolute;
            border: none;
            color: white;
            background: none;
            text-align: left;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            grid-gap: 10px;

            &--left {
                left: 5px;
            }

            &--right {
                right: 5px;
            }

            &__img {
                &--right {
                    transform: rotate(180deg);
                }
            }
        }

        &-events-button {
            top: calc(100% - 160px);
            transform: translateY(-100%);
        }

        &-seeking-button {
            top: calc(100% - 130px);
            transform: translateY(-100%);
        }
    }

    &__video-wrapper {
        @media screen and (max-width: 800px) {
            width: 100%;
        }

        @media screen and (min-width: 801px) {
            min-width: 100%;
        }

        &__img {
            &--right {
                transform: rotate(180deg);
            }
        }
    }

    &__server__time {
        @media (min-width: 480px) {
            padding: 35px 20px 10px;
        }

        @media (max-width: 479px) {
            padding: 20px 15px 5px;
        }

        top: -40px;
        left: 0px;
        position: absolute;
    }

    &__tooltip {
        margin-bottom: calc(var(--plyr-control-spacing, 18px) / 2 * 2) !important;
    }

    &--full-ui {
        color: rgba(255, 255, 255, 0.0) !important;

        @media screen and (max-width: 800px) {
            display: contents;
            height: -webkit-fill-available;
            width: -webkit-fill-available;
        }
    }

    &__poster {
        display: none;
    }
}
