.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  @media only screen and (max-width: 680px) {
    &.sm-column {
      flex-direction: column;
    }
  }
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.uppercase {
  text-transform: uppercase;
}
