@import "variables";

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $primary-accent !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-accent !important;
}

.mat-radio-ripple {
    .mat-radio-persistent-ripple,
    .mat-ripple-element {
        background-color: $primary-accent !important;
    }
}