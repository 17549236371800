mat-list,
mat-selection-list {
    &.bordered-list {
        border-width: 1px;
        border-style: solid;
        border-color: #e0e0e0e0;
        border-radius: 8px;
        margin-bottom: 0.25em + 1.34375em;

        mat-list-item {
            height: auto !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
    }

    &.none-padding {
        padding-bottom: 0px;
    }

    &.none-margin {
        margin: 0;
    }
}