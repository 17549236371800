@import "../variables";

.solution {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: $error-cron;
  border-radius: 16px;
  align-items: center;
  gap: 5px;

  @media (max-width: 650px) {
    flex-direction: column;

    button {
      margin-top: 10px;
    }
  }

  &__text {
    h3, p {
      font-size: 16px;
    }

    h3 {
      margin: 0 0 8px 0;
      font-weight: 700;
    }

    p {
      margin: 0;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__action {
    button {
      background-color: $background-light;
      color: $primary-accent;
      border-radius: 12px;
      border: none;
      min-height: 25px;
      cursor: pointer;
    }
  }
}
