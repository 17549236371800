.info-table {
    max-width: 500px;
    margin: auto;

    .table-column {
        display: grid;
        grid-template-columns: auto minmax(min-content, max-content);
        border-radius: 8px;
        grid-column-gap: 5px;

        .title {
            text-align: left;
            margin: 10px 0px 10px 10px;
        }

        .value {
            text-align: right;
            margin: 10px 10px 10px 0px !important;
        }
    }

    .table-column-neutral {
        background: $neutral;
    }

    hr {
        &.event-divider {
            margin: 20px 0px !important;
        }
    }
}