@import "variables";

.mat-progress-spinner {

    .mat-primary,
    &[ng-reflect-color="primary"],
    &[color="primary"] {
        circle {
            stroke: $primary-accent !important;
        }
    }

    .mat-warn,
    &[ng-reflect-color="warn"],
    &[color="warn"] {
        circle {
            stroke: $warn-strong !important;
        }
    }

    .mat-error,
    &[ng-reflect-color="error"],
    &[color="error"] {
        circle {
            stroke: $error-strong !important;
        }
    }

    .mat-white,
    &[ng-reflect-color="white"],
    &[color="white"] {
        circle {
            stroke: white !important;
        }
    }
}