@import "variables";

.tpl-checklist {
  max-width: 500px;
  margin: auto;
  display: grid;
  grid-gap: 10px;

  &__title {
    text-align: center;
  }

  :not(.tpl-checklist-item.tpl-checklist-item--success) {
    .tpl-checklist-item-number {
      background: $neutral;
    }
  }

  :not(.tpl-checklist-item.tpl-checklist-item--selected) {
    .tpl-checklist-item-number {
      background: $neutral;
    }
  }

  .tpl-checklist-item {
    border-radius: 8px;
    display: grid;
    grid-template-columns: 60px auto;
    padding-right: 10px;

    &--selected {
      background: $neutral;
    }

    &.tpl-checklist-item--success {
      .tpl-checklist-item-number {
        background: $success;
        color: white;

        &__label {
          color: gray;
        }
      }
    }

    &.tpl-checklist-item--selected {
      background: $neutral;

      .tpl-checklist-item-number {
        background: $primary-accent;

        &__label {
          color: white;
        }
      }
    }

    .tpl-checklist-item-number {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: grid;
      margin: calc(1.33em / 2) auto auto auto;

      &__label {
        text-align: center;
        font-weight: bold;
        margin: auto;
      }
    }

    .tpl-checklist-item-content {
      &__image {
        width: 100%;
      }

      .tpl-checklist-item-content-list {
        display: grid;
        grid-gap: 10px;
        padding-left: 15px;

        &__link {
          word-break: break-all;
        }
      }

      .tpl-checklist-item-content-actions {
        display: flex;
        gap: 10px;
        margin: 10px 0;
      }
    }
  }
}

.tpl-instruction {
  &__title {
    text-align: center;
    margin-bottom: 0;
  }

  &__link {
    text-align: center;
    color: $primary-accent;
    cursor: pointer;
    font-weight: 600;
    display: block;
    text-decoration: underline;
  }
}

.tpl-complete-step {
  margin: 5px !important;
}
